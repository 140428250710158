import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { Field } from '@fluentui/react-components'
import { Select, SelectOption } from '../Select'

export type Serde<T> = {
    fromStr: (str: string) => T
    toStr: (val: T) => string
}

export function FormSelect<
    FormVals extends FieldValues,
    Field extends FieldPath<FormVals>,
    T = string
>({
      form,
      field,
      label,
      options,
      serde,
  }: {
    label: string
    form: UseFormReturn<FormVals>
    field: Field
    options: { value: T, label: string }[]
    serde?: Serde<T>
}) {
    return (
        <Controller
            name={field}
            control={form.control}
            render={({ field: { value, onChange, disabled } }) => {

                if (value != undefined && serde)
                    value = serde.toStr(value) as any

                return <Field label={label}>
                    <Select value={value}
                            disabled={disabled}
                            options={options.map(o => ({
                                label: o.label,
                                value: serde ? serde.toStr(o.value) : value,
                            }))}
                            onChange={val => {
                                if (val != undefined && serde)
                                    val = serde.fromStr(val) as any
                                onChange(val)
                            }} />
                </Field>
            }
            }
        />
    )
}

