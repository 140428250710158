import { useAppContext } from '../../ctx/MyContext'
import { css } from '@emotion/react'
import React, { ComponentType } from 'react'
import { useForm } from 'react-hook-form'
import prettyBytes from 'pretty-bytes'
import { ErrorMsg } from '../../ui/ErrorMsg'
import { useOpState } from '../../ui/useOpState'
import { InfoMsg } from '../../ui/InfoMsg'
import { Spinner } from '../../ui/Spinner'
import { Button, Field } from '@fluentui/react-components'
import { useApiData } from '../../api/useApiData'
import { useSearchConsoleStore } from '../SearchConsoleStore'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import { useCollectionStore } from '../../chat/SearchCollectionsStore'
import { DeleteButton } from '../../ui/DeleteButton'


export const AddDocuments: ComponentType<{ onSuccess: () => void }> = ({ onSuccess }) => {
    const { api } = useAppContext()

    const op = useOpState()

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        ...form
    } = useForm<{ files: FileList }>()

    const files = Array.from(watch('files') ?? [])
    const store = useCollectionStore()

    return (
        <div>
            <p>
                PDF or JSON (with string fields: 'id', 'text', 'title') files
                are accepted{' '}
            </p>
            {!op.pending && (
                <form
                    css={css`
                        //display: flex;
                        //flex-direction: column;
                    `}
                    onSubmit={handleSubmit(async (data) => {
                        await op.handle(
                            api.search
                                .setFromPdf(store.selected!.id, {
                                    files: Array.from(data.files),
                                })
                                .then(store.refreshStats)
                                .catch(err => {
                                    if (err.httpStatus == 413) {
                                        err = new Error('The combined file size is too large. Try uploading fewer files at a time or smaller files.')
                                    }
                                    throw err
                                }),
                        )
                        form.reset()
                        onSuccess()
                        // setValue('files', null as any)
                    })}
                >
                    <Field label={'Files to upload'}>
                        <input
                            css={css`
                                height: 3em;
                                border: 1px solid gray;
                                cursor: pointer;
                            `}
                            id={'files'}
                            multiple={true}
                            accept={'application/pdf, application/json'}
                            type="file"
                            {...register('files')}
                        />
                        <ul>
                            {files.map((f, i) => (
                                <li key={i}>
                                    {f.name} {prettyBytes(f.size)}
                                </li>
                            ))}
                        </ul>
                    </Field>
                    <Button type="submit"
                            appearance={'primary'}
                    >Submit</Button>
                    <ErrorMsg error={op.error} />
                    <InfoMsg shown={op.success}>Files uploaded</InfoMsg>
                </form>
            )}
            <Spinner shown={op.pending}>Uploading...</Spinner>
        </div>
    )
}

export const SourceDocsList: ComponentType<{}> = observer(() => {
    const collection = useCollectionStore()
    const store = useSearchConsoleStore()

    const { api } = useAppContext()

    const colId = collection.selected!.id

    // TODO checking whether Collection exists here
    const docs = useApiData(
        (api) => api.search.getCollectionSourceDocs(colId),
        {
            keys: [colId, collection.collectionInfo?.documentsCount],
        },
    )

    return (
        <div>
            <h3>Documents {docs.data ? `(${docs.data.length})` : ''}</h3>
            <Spinner shown={docs.pending} />
            {!docs.pending &&
                docs.data &&
                docs.data.map((doc) => (
                    <div
                        key={doc.id}
                        css={css`
                            display: flex;
                            height: 2.5em;
                            font-weight: ${store.document?.id === doc.id
                                    ? 'bold'
                                    : 'auto'};
                        `}
                        onClick={() => {
                        }}
                    >
                        <div
                            css={css`
                                width: 40em;
                                cursor: pointer;
                            `}
                            onClick={() => {
                                action(() => {
                                    store.document = doc
                                })()

                            }}>
                            {doc.name} [{doc.type}]
                        </div>
                        <DeleteButton onDelete={async () => {
                            await api.search.deleteCollectionSourceDoc(colId, doc.id)
                            collection.refreshStats()
                            docs.refetch()

                            if (store.document?.id === doc.id) {
                                store.document = undefined
                            }
                        }} />
                    </div>
                ))}
        </div>
    )
})
