import { InvoiceParserApp } from './InvoiceParserSubapp'
import { AuthProvider } from '../auth/AuthProvider'
import { BrowserRouter } from 'react-router-dom'
import { ComponentType } from 'react'
import { useAppContext } from '../ctx/MyContext'

const WithSessionOnly: ComponentType<{ comp: ComponentType }> = ({ comp: Comp }) => {
    const ctx = useAppContext()
    const store = ctx.auth.store

    if (!store.isHydrated)
        return null

    const child = !store.hasSession ? <div>Not logged in</div>
        : !store.isAdmin ? <div>
                Admin only
            <button onClick={() => {
                store.logout(getCurrentPath())
            }}>Logout</button>
        </div> :
            <Comp />


    return <div>
        {child}
    </div>

}

const getCurrentPath = () => {
    let path = window.location.href.split('//')[1]
    path = path.split('/')[1]
    path = '/' + path
    return path
}

export const getSubappComp = (id: string): React.ComponentType | null => {
    const Comp = getChosenSubappComp(id)
    if (!Comp)
        return null

    return () =>
        <BrowserRouter>
            <AuthProvider requireSession={true} loginCb={getCurrentPath()}>
                <WithSessionOnly comp={Comp} />
            </AuthProvider>
        </BrowserRouter>
}


const getChosenSubappComp = (id: string): React.ComponentType | null => {
    if (id === 'invpar')
        return InvoiceParserApp
    return null
}
