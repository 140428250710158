import React, { ComponentType } from 'react'
import { Select as FuiSelect } from '@fluentui/react-components'

export type SelectOption = {
    value: string
    label: string
}

export const simpleSelectOptions = (vals: string[]): SelectOption[] =>
    vals.map((value) => ({ value, label: value }))

export const Select: ComponentType<{
    options: SelectOption[]
    value?: string
    horizontal?: boolean
    disabled?: boolean,
    onChange: (val: string) => void
}> = ({ options, value, onChange, horizontal, disabled }) => (
    <FuiSelect
        disabled={disabled}
        value={value}
        onChange={(_, v) => onChange(v.value)}
    >
        {options.map((o) => (
            <option key={o.value} value={o.value}>
                {o.label}
            </option>
        ))}
    </FuiSelect>
)
