import { action, observable } from 'mobx'
import { MyApi } from '../api/api'
import { useAppContext } from '../ctx/MyContext'
import { Collection, CollectionUpdateCmd, SearchCollectionInfo } from '@apis/my'

export class SearchCollectionStore {
    constructor(private api: MyApi) {
        // makeObservable(this)
    }

    @observable accessor items: Collection[] | undefined = undefined

    // Keep track of deleted ones in this use session..
    @observable accessor deleted: number[] = []

    @observable accessor selected: Collection | undefined = undefined

    @observable accessor collectionInfo: SearchCollectionInfo | undefined =
        undefined

    fetch = async () => {
        this.items = await this.api.search.getCollections()
        if (!this.selected) {
            this.selectFirstIfPossible()
        }
    }

    @action.bound // TODO should work with arrow functinos as well (but it doesn)
    async delete(id: number) {
        await this.api.search.deleteCollection(id)
        this.deleted.push(id)

        this.items = this.items?.filter((c) => c.id !== id)
        if (this.selected?.id === id) {
            this.selected = undefined
            this.selectFirstIfPossible()
        }
    }

    private selectFirstIfPossible = () => {
        if (!this.items?.length) return
        this.selected = this.items![0]
        this.refreshStats()
    }

    @action.bound // TODO should work with arrow functinos as well (but it doesn)
    select(id: number) {
        this.selected = this.items!.find((c) => c.id === id)
        this.refreshStats()
    }

    @action.bound
    async update(id: number, req: CollectionUpdateCmd) {
        const col = await this.api.search.updateCollection(id, req)
        const idx = this.items!.findIndex(c => c.id === id)!
        this.items![idx] = col
        this.selected = col
    }

    @action.bound
    refreshStats() {
        this.api.search.getCollectionInfo(this.selected!.id).then((r) => {
            this.collectionInfo = r
        })
    }
}

export const useCollectionStore = (): SearchCollectionStore => {
    return useAppContext().collection.store
}
