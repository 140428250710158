import { ChatbotSettings, ChatbotSettingsWriteReq, MyApi } from '@apis/my'
import { observable } from 'mobx'


export class ChatbotSettingsStore {

    private api: MyApi['chatbotSettings']

    constructor(
        rootApi: MyApi,
    ) {
        this.api = rootApi.chatbotSettings
    }

    @observable accessor all: ChatbotSettings[] | undefined = undefined

    fetchData = async () => {
        this.all = await this.api.getAll()
    }

    add = async (s: ChatbotSettingsWriteReq): Promise<ChatbotSettings> => {
        this.all = await this.api.add(s)
        return this.all.find(r => r.name === s.name)!
    }

    update = async (id: number, s: ChatbotSettingsWriteReq): Promise<void> => {
        const val = await this.api.saveSettings(id, s)
        const idx = this.all!.findIndex(s => s.id === id)
        Object.assign(this.all![idx], val)
    }

    delete = async (id: number): Promise<void> => {
        await this.api.delete(id)
        await this.fetchData()
    }

}
