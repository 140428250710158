import { FieldValues } from 'react-hook-form/dist/types/fields'
import { FieldPath } from 'react-hook-form/dist/types/path/index'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Field, Input } from '@fluentui/react-components'
import React from 'react'


export function FormInput<
    FormVals extends FieldValues,
    Field extends FieldPath<FormVals>,
>({
      form,
      field,
      label,
      disabled,
      required,
  }: {
    label: string
    disabled?: boolean
    form: UseFormReturn<FormVals>
    required?: boolean
    field: Field
}) {
    return (
        <Controller
            name={field}
            rules={{
                required,
            }}
            control={form.control}
            render={({ field: { value, onChange, disabled: _disabled, ref } }) => {

                return <Field label={label}>
                    <Input value={value}
                           ref={ref}
                           disabled={disabled || _disabled}
                           onChange={val => {
                               onChange(val)
                           }} />
                </Field>
            }
            }
        />
    )
}
