import { css } from '@emotion/react'
import React, { ComponentType, ReactNode } from 'react'
import { Button } from '@fluentui/react-components'

export const AdminPage: ComponentType<{
    title: string,
    children: ReactNode,
    titleLeftContent?: ReactNode
    titleButton?: {
        onClick: () => void,
        title: string
    }
}> = ({
          title,
          children,
          titleLeftContent,
          titleButton,
      }) => {

    return <div>
        <div
            css={css`
                display: flex;
                align-items: center;
                width: 50em;
            `}>

            <h1>{title}</h1>
            {titleLeftContent}
            {titleButton &&
                <Button
                    css={css`
                        margin-left: auto;
                    `}
                    size={'small'}
                    onClick={() => {
                        titleButton.onClick()
                    }}
                    appearance={'primary'}>
                    {titleButton.title}
                </Button>
            }
        </div>
        {children}
    </div>

}
