import { MyApi } from './api'
import { useAppContext } from '../ctx/MyContext'
import { useEffect, useState } from 'react'

export const useApi = (): MyApi => useAppContext().api

let id = 1

export const useApiData = <D>(get: (api: MyApi) => Promise<D>): D | null => {
    const api = useApi()

    const [data, setData] = useState<D | null>(null)

    useEffect(() => {
        get(api).then(setData)
    }, [])

    return data
    // return useQuery(`query-${id++}`, () => get(api))
}

export const useDefaultValApiData = <D>(get: (api: MyApi) => Promise<D>, def: D): D => {
    const data = useApiData(get)
    return data || def
}
