import { observable } from 'mobx'
import { FrontendConf, MyApi } from '@apis/my'

export type AppSettings = {
    flags: FrontendConf['flags']
    name: string
    version: string
    branding: FrontendConf['branding']
}

export class AppStore {

    @observable accessor settings: AppSettings


    constructor(private api: MyApi, conf: FrontendConf) {
        this.settings = {
            version: conf.version,
            name: conf.title,
            flags: conf.flags,
            branding: conf.branding,
        }
    }
}
