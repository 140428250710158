// TODO refactor later
import React, { useState } from 'react'
import { useAppContext } from '../ctx/MyContext'


export const InvoiceParserApp = () => {
    const ctx = useAppContext()

    const [msg, setMsg] = useState('')
    return <div>
        <h1>Invoice parser POC</h1>
        <button onClick={() => {
            ctx.api.subappInvpar.getFoo().then(setMsg)
        }}>Go
        </button>
        <p>{msg}</p>
    </div>
}

