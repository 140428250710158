import { AnalysisPanelTabs } from './AnalysisPanelTabs'
import { AskResponse, ChatbotThoughtProcessExplanation, DataPoint } from '@apis/my'
import { FacadeDocumentContent } from '../../document/FacadeDocumentContent'
import React, { ComponentType } from 'react'
import { css } from '@emotion/react'
import { TextWithWhiteSpace } from '../../ui/TextWithWhiteSpace'
import { Tabs } from '../../ui/Tabs'

interface Props {
    className: string
    activeTab: AnalysisPanelTabs
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void
    activeCitation: string | undefined
    citationHeight: string
    answer: AskResponse
}

export const AnalysisPanel = ({
                                  answer,
                                  activeTab,
                                  activeCitation,
                                  citationHeight,
                                  className,
                                  onActiveTabChanged,
                              }: Props) => {
    const isDisabledThoughtProcessTab: boolean = !answer.thoughts
    const isDisabledSupportingContentTab: boolean = !answer.dataPoints.length
    const isDisabledCitationTab: boolean = !activeCitation


    const citation = activeCitation && <FacadeDocumentContent
        title={'Citation'}
        name={activeCitation}
        height={citationHeight}
    />


    return <div
        css={
            css`
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
            `
        }
    >
        <Tabs
            css={
                css`
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    // TODO harcoded height !!
                    max-height: 80vh;
                `
            }
            selected={{
                val: activeTab,
                set: t => onActiveTabChanged(t as AnalysisPanelTabs),
            }}
            tabs={[
                {
                    label: 'Thought process',
                    key: AnalysisPanelTabs.ThoughtProcessTab,
                    disabled: isDisabledThoughtProcessTab,
                    render: () => <ThoughtProcess value={answer.thoughts} />,
                },
                {
                    label: 'Supporting content',
                    key: AnalysisPanelTabs.SupportingContentTab,
                    disabled: isDisabledSupportingContentTab,
                    render: () => <SupportingContent points={answer.dataPoints} />,
                },
                {
                    label: 'Citation',
                    disabled: isDisabledCitationTab,
                    key: AnalysisPanelTabs.CitationTab,
                    render: () => citation,
                },
            ]}
        />
    </div>


}

export const ThoughtProcess: ComponentType<{ value: ChatbotThoughtProcessExplanation }> = ({ value }) => {
    const { answer, answerPrompt } = value
    return <div>
        <h3>Query prompt</h3>
        <TextWithWhiteSpace>{value.queryPrompt}</TextWithWhiteSpace>
        <h3>Query</h3>
        <TextWithWhiteSpace>
            {value.query}
        </TextWithWhiteSpace>
        {answerPrompt &&
            <>
                <h3>Answer prompt (conversation)</h3>
                {answerPrompt.map(((m, i) =>
                        <div key={i}>
                            <div css={css`
                                font-weight: bold;
                            `}>
                                {m.role}
                            </div>
                            <TextWithWhiteSpace>

                                {m.content}
                            </TextWithWhiteSpace>
                        </div>
                ))}
            </>
        }
        {answer && <>
            <h3>Answer (from assistant)</h3>
            <p>
                {value.answer}
            </p>
        </>}
    </div>
}

export const SupportingContent: ComponentType<{ points: DataPoint[] }> = ({
                                                                              points,
                                                                          }) => {
    return (
        <ul>
            {points.map((p) => {
                return (
                    <div>
                        <h4>
                            {p.name}
                        </h4>
                        <TextWithWhiteSpace>
                            {p.content}
                        </TextWithWhiteSpace>
                    </div>
                )
            })}
        </ul>
    )
}
