import { SearchConsole } from './SearchConsole'
import { Link, Navigate, NavLink, Route, Routes } from 'react-router-dom'
import React from 'react'
import { css, useTheme } from '@emotion/react'
import { PdfExtractor } from './PdfExtractor'
import { CollectionPage } from './CollectionPage'
import { CollectionSelector } from './CollectionSelector'
import { useAppContext } from '../ctx/MyContext'
import { QaLogs } from './qa-logs/QaLogs'
import { GlobalSettings } from './GlobalSettings'
import { SourceDocsPage } from './docs/SourceDocsPage'
import { ChatbotSettingPage } from './pages/ChatbotSettingPage'

export const paths = {
    searchConsole: '/playground-search-console',
}

const menuItems = [
    {
        label: 'Collection',
        path: './collection',
    },
    {
        label: 'Search console',
        path: './search-console',
    },
    {
        label: 'Source documents',
        path: './source-docs',
    },
    {
        label: 'PDF content extractor',
        path: './pdf-extractor',
    },
    {
        label: 'QA logs',
        path: './qa-logs',
    },
    {
        label: 'Global settings',
        path: './global-settings',
    },
    {
        label: 'Chatbot settings',
        path: './chatbot-settings',
    },
]

export const Admin = () => {
    const t = useTheme()
    const app = useAppContext().app.store
    return (
        <div
            css={css`
                display: flex;
                //height: 100%;
                flex-grow: 1;
                background: white;
            `}
        >
            <div css={css`
                width: 16em;
                padding: 1em;
                background: white;
            `}>
                <Link
                    to={'/'}
                    css={css`
                        font-weight: bold;
                        text-decoration: none;
                        //color: black;
                        color: inherit;
                    `}
                >
                    Back to chatbot
                </Link>
                <div
                    css={css`
                        margin-bottom: 2em;
                    `}
                />
                <h3>Collections</h3>
                <CollectionSelector />
            </div>
            <div
                css={css`
                    width: 100%;
                `}
            >
                <div css={css`
                    padding-top: 1em;
                    padding-bottom: 1em;
                    display: flex;
                    background: white;
                `}>
                    {menuItems.map((i) => (
                        <NavLink
                            key={i.path}
                            to={i.path}
                            style={({ isActive }) => ({
                                color: isActive ? t.color : 'black',
                            })}
                            css={(t) => css`
                                margin-right: 1em;
                                font-weight: bold;
                                font-size: 1.2em;
                                text-decoration: none;
                                color: black;
                            `}
                        >
                            {i.label}
                        </NavLink>
                    ))}
                    <div css={css`
                        margin-left: auto;
                        margin-right: 1em;
                    `}>
                        Version: {app.settings.version}
                    </div>
                </div>
                <div css={css`
                    padding-left: 0.5em;
                `}>
                    <Routes>
                        <Route path="/collection" element={<CollectionPage />} />
                        <Route path="/source-docs" element={<SourceDocsPage />} />
                        <Route path="/pdf-extractor" element={<PdfExtractor />} />
                        <Route path="/search-console" element={<SearchConsole />} />
                        <Route path="/qa-logs" element={<QaLogs />} />
                        <Route path="/global-settings" element={<GlobalSettings />} />
                        <Route path="/chatbot-settings" element={<ChatbotSettingPage />} />
                        <Route
                            path="*"
                            element={<Navigate to="./collection" replace />}
                        />
                    </Routes>
                </div>
            </div>
        </div>
    )
}
