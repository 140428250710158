import { makeAutoObservable } from 'mobx'
import { MyApi } from '@apis/my'
import { isHydrated, makePersistable } from 'mobx-persist-store'
import { UiControls } from '../ctx/UiControls'

export const LOGIN_SUCCESS_QUERY_PARAM = 'login_success'

export const LOGIN_FAILED_QUERY_PARAM = 'login_failed'

export class AuthStore {

    // @observable accessor - does not work with mobx-persist-store
    currentUser: {
        name: string
        admin: boolean
    } | null = null

    fetchingUser = false

    get isAdmin(): boolean {
        return !!this.currentUser?.admin
    }

    get hasSession(): boolean {
        return !!this.currentUser
    }

    constructor(private api: MyApi,
                private ui: UiControls,
                public publicAccessAllowed: boolean,
                storage: Storage) {
        makeAutoObservable(this, {
            // httpClient: false,
        }, {
            autoBind: true
        })
        makePersistable(
            this,
            {
                name: 'AuthStore',
                properties: ['currentUser'],
                storage
                // debugMode: true,
            },
            {
                fireImmediately: true
                // delay: 200,
                // requiresObservable: true
                // fireImmediately: true,
            }
        )
    }

    initiateLogin = async (cbPath: string) => {

        const r = await this.api.auth.initiateLogin({
            cb: cbPath
        })
        if (r.done) {
            this.loadCurrentUser()
        } else {
            if (!r.redirect)
                throw new Error(`Expected redirect response`)
            window.location.href = r.redirect
        }
    }

    loadCurrentUser = async () => {
        this.fetchingUser = true
        const user = await this.api.user.userInfo()
        this.fetchingUser = false
        this.currentUser = user
    }

    logout = async (path: string = '/') => {
        await this.api.auth.logout()
        this.currentUser = null
        this.ui.showToast('You have been logged out successfully')
        this.ui.navigate(path)
    }

    get isHydrated() {
        return isHydrated(this)
    }
}
