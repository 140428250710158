// TODO refactor later
import React, { useEffect } from 'react'
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { ChatbotScreen } from './chat/ChatbotScreen'
import NoPage from './pages/NoPage'
import { Admin } from './admin/Admin'
import { AuthProvider } from './auth/AuthProvider'
import { useAppContext } from './ctx/MyContext'
import Layout from './pages/Layout'

const RootLayout = () => {
    const navigate = useNavigate()
    const ctx = useAppContext()

    useEffect(() => {
        ctx.ui.register.navigate(navigate)
    }, [])


    return (
        <Layout>
            <AuthProvider loginCb={'/'}>
                <Outlet />
            </AuthProvider>
        </Layout>
    )
}

const PrivateZoneLayout = () => {
    const ctx = useAppContext()

    const { collection, chat } = ctx

    useEffect(() => {
        collection.store.fetch()
        chat.settingsStore.fetchData()
    }, [])

    return <Outlet />
}

export default function Routing() {
    // {initEl && <Route path="/" element={props.initEl} />}
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<RootLayout />}>
                    <Route path={'/'} element={<PrivateZoneLayout />}>
                        <Route index element={<ChatbotScreen />} />
                        <Route path="*" element={<NoPage />} />
                        <Route path="/admin/*" element={<Admin />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
