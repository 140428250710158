import React, { useContext } from 'react'
import Axios from 'axios'
import { FrontendConf, MyApi } from '@apis/my'
import { createHttpClient } from '@ps-aux/api-client-axios'
import { SearchConsoleStore } from '../admin/SearchConsoleStore'
import { SearchCollectionStore } from '../chat/SearchCollectionsStore'
import { ChatStore } from '../chat/ChatStore'
import { UiControls } from './UiControls'
import { AuthStore } from '../auth/Auth.store'
import { AppStore } from '../app/App.store'
import { ChatbotSettingsStore } from '../admin/chatbot/settings/ChatbotSettings.store'

export type MyContext = {
    api: MyApi
    app: {
        store: AppStore
    },
    auth: {
        store: AuthStore
    }
    collection: {
        store: SearchCollectionStore
    }
    search: {
        consoleStore: SearchConsoleStore
    }
    chat: {
        store: ChatStore,
        settingsStore: ChatbotSettingsStore
    }
    ui: UiControls
}

export const createAppContext = (conf: FrontendConf): MyContext => {

    const ui = new UiControls()
    const axios = Axios.create()
    const api = new MyApi(createHttpClient(axios))

    const authStore = new AuthStore(api, ui, conf.publicAccess, window.localStorage)

    axios.interceptors.response.use(
        (r) => r,
        (error) => {
            // TODO refine more
            const httpStatus = error.response?.status
            if (httpStatus === 401) {
                console.error('Got 401. Removing user session')
                authStore.currentUser = null
                ui.showToast('Session no longer valid')
            }

            const msg = error.response?.data?.message
            if (msg) {
                error = new Error(msg)
            }
            error.httpStatus = httpStatus

            ui.showToast(error.message, {
                error: true
            })

            return Promise.reject(error)
        }
    )

    const collectionStore = new SearchCollectionStore(api)
    const cbsStore = new ChatbotSettingsStore(api)
    return {
        api,
        app: {
            store: new AppStore(api, conf)
        },
        chat: {
            store: new ChatStore(api, collectionStore, cbsStore),
            settingsStore: cbsStore
        },
        collection: {
            store: collectionStore
        },
        search: {
            consoleStore: new SearchConsoleStore(api, collectionStore)
        },
        auth: {
            store: authStore
        },
        ui
    }
}

const MyContext_ = React.createContext<MyContext>(null as any)

export const MyContextProvider = MyContext_.Provider

export const useAppContext = (): MyContext => {
    return useContext(MyContext_)
}
