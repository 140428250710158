import { Text } from '@fluentui/react-components'
import { Settings24Regular } from '@fluentui/react-icons'
import { css } from '@emotion/react'

export interface ButtonProps {
    className?: string
    children: string
    onClick: () => void
    disabled?: boolean
    icon?: typeof Settings24Regular // TODO simple hack
}

export const Button = ({ className, onClick, icon: Icon, children, disabled }: ButtonProps) => {
    return (
        <button
            className={className}
            css={css`
              display: flex;
              align-items: center;
              gap: 6px;
              cursor: pointer;
              border: none;
              opacity: ${disabled? 0.4 : 1};
            `}
            onClick={onClick}
        >
            {Icon && <Icon />}
            <Text>{children}</Text>
        </button>
    )
}
